import ApolloClient from 'apollo-boost';

import 'isomorphic-fetch';

const client = new ApolloClient({
	uri: process.env.GATSBY_WPGRAPHQL_ENDPOINT, 
	 
});

export default client;

